*{
  margin: 0px;
  padding: 0px;

}

.App{
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
/* background-image: url('./pic//techjourneyer_72218_a_tech_person_climibing_a_summit_to_announc_479e82a3-a090-47b6-b56e-d8af0428cc3c.webp'); */
background:linear-gradient(#010203b8,#010203b8 ), url('./pic//techjourneyer_72218_a_tech_person_climibing_a_summit_to_announc_479e82a3-a090-47b6-b56e-d8af0428cc3c-ezgif.com-webp-to-jpg-converter.jpg');
background-position: center;

background-repeat: no-repeat;
background-size: cover;
}

.header{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}
h2{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: white;
  font-size: 30px;
  box-shadow: 10px;
}
.txt{
  color: white;
  font-size: 15px;
}
.main{
  display:flex;
  text-align: center;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  row-gap: 30px;
  height: 90vh;
}
.content{
  display:flex;
  text-align: center;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  row-gap: 30px;
  /* height: 90vh; */
  margin-top: 334px;
}
.logo{
  color: #ffc315;
  font-size: 30px;
  /* font-style: bold; */
  font-weight: bolder;
}
.log{
width: 30px;
height: 30px;

}
.log img{
  width: 100%;
  height: 100%;
}



@media (min-width: 1000px) and (max-width: 768px) {
  /* Your styles for small screens go here */
}
@media  (max-width: 1000px) {
  /* Your styles for small screens go here */
  .content{
    max-width: 620px;
  }
}
@media  (max-width: 400px) {
  /* Your styles for small screens go here */
  h2{
    font-size: 25px;
    /* max-width: 620px; */
  }
}
